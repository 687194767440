<template>
  <div class="home">
    <eden-page-header :title="'Directory'">
      <template slot="actions">
        <div class="page--head-actions is-flex is-flex-wrap align-center">
          <el-button type="primary" @click="add">
            Add {{ tabs[tab] }}
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <eden-page-stats :title="'Overview'" :stats="stats" />
    <el-tabs v-model="tab" @tab-click="updateRouteQuery">
      <el-tab-pane label="Rider Directory" name="riders">
        <riders :total-riders.sync="totalRiders" />
      </el-tab-pane>
      <el-tab-pane label="Cleaners Directory" name="cleaners">
        <cleaners :total-cleaners.sync="totalCleaners" />
      </el-tab-pane>
      <el-tab-pane
        v-if="enableBeautyDirectory"
        label="Beautician Directory"
        name="beauticians"
      >
        <beauticians :total-beauticians.sync="totalBeauticians" />
      </el-tab-pane>
      <el-tab-pane label="Companies Directory" name="companies">
        <companies :total-companies.sync="totalCompanies" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Riders from "@/components/Logistics/Directory/Riders/Riders.vue";
import Cleaners from "@/components/Logistics/Directory/Cleaners/Cleaners.vue";
import Beauticians from "@/components/Logistics/Directory/Beauticians/Beauticians";
import Companies from "@/components/Logistics/Directory/Companies/Companies.vue";

export default {
  name: "Directory",
  components: {
    Riders,
    Cleaners,
    Beauticians,
    Companies,
  },
  data() {
    return {
      tab: "riders",
      tabs: {
        riders: "Rider",
        cleaners: "Cleaner",
        beauticians: "Beautician",
        companies: "Company",
      },
      totalRiders: {
        food: 0,
        laundry: 0,
      },
      totalCleaners: 0,
      totalBeauticians: 0,
      totalCompanies: 0,
    };
  },
  computed: {
    location() {
      return this.$store.getters.location;
    },
    stats() {
      return [
        {
          label: "Food riders (Active)",
          figure: this.totalRiders.food,
        },
        {
          label: "Laundry riders (Active)",
          figure: this.totalRiders.laundry,
        },
        {
          label: "Active Cleaners",
          figure: this.totalCleaners,
        },
        {
          label: "Companies (Third-party)",
          figure: this.totalCompanies,
        },
      ];
    },
    enableBeautyDirectory() {
      return (
        this.location === "KE" &&
        this.allowAccessFor(["admin"])
      );
    },
  },
  created() {
    const { t } = this.$route.query;
    this.tab = t ? t : "riders";
  },
  methods: {
    updateRouteQuery(tab) {
      const currentTab = this.$route.query.t;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "logistics.directory.index",
        query: { t: tab.name },
      });
    },
    add() {
      const tab = this.tabs[this.tab].toLowerCase();
      this.$router.push({ name: `logistics.directory.${tab}-add` });
    },
  },
};
</script>
