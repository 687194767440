<template>
  <div>
    <eden-table-actions :title="title" @search="searchCompanies" />
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column width="220">
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'logistics.directory.company',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatName(scope.row.name) }}</span
                ></router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tag :type="setType(scope.row.status)">
                {{ formatText(scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Contact Email</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.contact_email }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Contact Phone Number</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatPhoneNumber(scope.row.contact_phone) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Address</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.address }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="allowAccessFor(['admin', 'operations'])"
            width="80"
          >
            <template slot-scope="scope">
              <company-actions :company="scope.row" @update="getCompanies" />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
  </div>
</template>

<script>
import companies from "@/requests/logistics/directory/companies";
import * as actions from "@/store/action-types";

export default {
  name: "Companies",
  components: {
    CompanyActions: () => import("./Company/Actions/CompanyActions"),
  },
  props: {
    totalCompanies: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
    };
  },

  computed: {
    companies() {
      return this.$store.getters.directory.companies;
    },
    from() {
      return this.companies.pages[this.page].from;
    },
    to() {
      return this.companies.pages[this.page].to;
    },
    total() {
      return this.companies.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Companies`;
    },
    setTotalCompanies: {
      get() {
        return this.totalCompanies;
      },
      set(value) {
        this.$emit("update:totalCompanies", value);
      },
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.companies.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getCompanies();
      }
    },
  },
  created() {
    this.getCompanies();
  },

  methods: {
    getCompanies() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_LOGISTICS_DIRECTORY, {
          directory: "companies",
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchCompanies(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      companies
        .search(query)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = data.data;
            this.showPagination = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.companies.pages[this.page].data;
      this.setTotalCompanies = this.total;
      this.showPagination = true;
    },
  },
};
</script>
