<template>
  <div>
    <eden-table-actions :title="title" @search="searchRiders">
      <template slot="actions">
        <riders-export :loading="loading" :data="pageData" />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'logistics.directory.rider',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatFullName(scope.row) }}</span
                ></router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status" :type="setType(scope.row.status)">
                {{ formatText(scope.row.status) }}
              </el-tag>
              <span v-else class="font-sm">-</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Phone Number</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatPhoneNumber(scope.row.phone_number) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Company</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.company && formatText(scope.row.company.name) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Service</span>
              </div>
            </template>
            <template slot-scope="scope">
              <eden-services-list
                v-if="scope.row.services.length"
                :services="scope.row.services"
              />
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Location Area</span>
              </div>
            </template>
            <template slot-scope="scope">
              <rider-location-areas
                :location-areas="scope.row.location_areas"
              />
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Added on</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">{{
                formatDate(scope.row.created_at, "do m, y")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="allowAccessFor(['admin', 'operations'])"
            width="80"
          >
            <template slot-scope="scope">
              <rider-actions :rider.sync="scope.row" @update="getRiders" />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
  </div>
</template>

<script>
import riders from "@/requests/logistics/directory/riders";
import * as actions from "@/store/action-types";

export default {
  name: "Riders",
  components: {
    RidersExport: () => import("./RidersExport"),
    RiderActions: () => import("./Rider/Actions/RiderActions"),
    RiderLocationAreas: () => import("./Rider/RiderLocationAreas"),
  },
  props: {
    totalRiders: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
    };
  },

  computed: {
    riders() {
      return this.$store.getters.directory.riders;
    },
    from() {
      return this.riders.pages[this.page].from;
    },
    to() {
      return this.riders.pages[this.page].to;
    },
    total() {
      return this.riders.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Riders`;
    },
    setTotalRiders: {
      get() {
        return this.totalRiders;
      },
      set(value) {
        this.$emit("update:totalRiders", value);
      },
    },
  },

  watch: {
    page() {
      const pageFetched = !!this.riders.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getRiders();
      }
    },
  },
  created() {
    this.getRiders();
  },

  methods: {
    getRiders() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_LOGISTICS_DIRECTORY, {
          directory: "riders",
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.setRidersByService();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchRiders(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      riders
        .search(query)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = data.data;
            this.showPagination = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.parseData(this.riders.pages[this.page].data);
      this.showPagination = true;
    },
    setRidersByService() {
      let foodRiders = this.pageData.filter(
        (rider) => rider.services.includes("meal") && rider.status === "active",
      );
      let laundryRiders = this.pageData.filter(
        (rider) =>
          rider.services.includes("laundry") && rider.status === "active",
      );

      this.setTotalRiders = {
        food: foodRiders.length ?? 0,
        laundry: laundryRiders.length ?? 0,
      };
    },
  },
};
</script>
