<template>
  <div>
    <eden-table-actions :title="title" @search="searchCleaners">
      <template slot="actions">
        <cleaners-export :loading="loading" :data="pageData" />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column width="220">
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template slot-scope="scope">
              <router-link
                :to="{
                  name: 'logistics.directory.cleaner',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatFullName(scope.row) }}</span
                ></router-link
              >
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
              </div>
            </template>
            <template slot-scope="scope">
              <el-tag :type="setType(scope.row.status)">
                {{ formatText(scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Phone Number</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatPhoneNumber(scope.row.phone_number) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="150">
            <template slot="header">
              <div class="table--header">
                <span>Location area they live</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.location_area && scope.row.location_area.name }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot="header">
              <div class="table--header">
                <span>Gender</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatText(scope.row.gender) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Preferred Days</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span
                class="is-flex flex-wrap"
                v-if="scope.row.preferred_working_days.length"
                >{{ setWorkingDays(scope.row.preferred_working_days) }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if="allowAccessFor(['admin', 'operations'])"
            width="80"
          >
            <template slot-scope="scope">
              <cleaner-actions
                :cleaner.sync="scope.row"
                @update="getCleaners"
              />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No data'" />
    </template>
  </div>
</template>

<script>
import cleaners from "@/requests/logistics/directory/cleaners";
import * as actions from "@/store/action-types";

export default {
  name: "Cleaners",
  components: {
    CleanersExport: () => import("./CleanersExport"),
    CleanerActions: () => import("./Cleaner/Actions/CleanerActions"),
  },
  props: {
    totalCleaners: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
    };
  },

  computed: {
    cleaners() {
      return this.$store.getters.directory.cleaners;
    },
    from() {
      return this.cleaners.pages[this.page].from;
    },
    to() {
      return this.cleaners.pages[this.page].to;
    },
    total() {
      return this.cleaners.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Cleaners`;
    },
    setTotalCleaners: {
      get() {
        return this.totalCleaners;
      },
      set(value) {
        this.$emit("update:totalCleaners", value);
      },
    },
  },

  watch: {
    page() {
      const pageFetched = !!this.cleaners.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getCleaners();
      }
    },
  },
  created() {
    this.getCleaners();
  },

  methods: {
    getCleaners() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_LOGISTICS_DIRECTORY, {
          directory: "cleaners",
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    searchCleaners(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      cleaners
        .search(query)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = data.data;
            this.showPagination = false;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.cleaners.pages[this.page].data;
      this.setTotalCleaners = this.total ?? 0;
      this.showPagination = true;
    },
    setWorkingDays(days) {
      return this.formatToTitleCase(
        this.sortWeekDays(days, "short").join(", "),
      );
    },
  },
};
</script>
